import React from "react";
import Container from "reactstrap/lib/Container";
import ValidationForm from "../../../validation/form";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Joi from "joi-browser";
import { showLoadingRecordsMessage } from "config/config";
import utils from "util/CustomUtil";
import { BRANDED_TRACKING_NUMBER } from "constants/index";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Timeline from "views/pages/member/timeline/Timeline";
import shipmentService from "services/shipmentService";
import BrandedNavbar from "./BrandedNavbar.js";
import Alert from "reactstrap/lib/Alert";
import DefaultTrackShipment from "views/pages/general/TrackShipment.js";
import { Button, Card, CardBody, Modal } from "reactstrap";

class TrackShipment_1 extends ValidationForm {
  state = {
    data: {},
    errors: {},
    formSubmitted: true,
    isLoading: false,
    trackingDetail: {},
    redirectToDefaultTrackingPage: false,
    productList: [],
    detailImageModal: false,
    isDefaultTrackingPage: false,
    loadingError: false,
  };

  schema = {
    trackingNumber: Joi.number().min(1).required().label("Tracking Number"),
  };

  captionStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    maxHeight: "240px",
    overflow: "hidden",
    position: "absolute",
    bottom: "0",
    width: "100%",
    color: "white",
    padding: "2px",
    fontSize: "90%",
  };

  customTagStyle = {
    wordWrap: "break-word",
    display: "inline-block",
    backgroundColor: "white",
    height: "auto",
    fontSize: "75%",
    fontWeight: "600",
    lineHeight: "1",
    padding: ".2em .6em .3em",
    borderRadius: ".25em",
    color: "black",
    verticalAlign: "baseline",
    margin: "2px",
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    const data = this.state.data;
    let trackingNumber = "";
    let subdomain = "";

    if (this.props.testPage) {
      trackingNumber = BRANDED_TRACKING_NUMBER;
      subdomain = this.props.subdomain;
      this.setState({ productList: this.props.productList });
    } else {
      trackingNumber = this.props.match.params.trackingNumber;
      subdomain = this.props.subdomain;
    }

    if (trackingNumber && subdomain) {
      data.trackingNumber = trackingNumber;
      data.subdomain = subdomain;

      this.setState({ data }, () => this.doSubmit());
    }
  }

  async doSubmit() {
    this.setState({
      formSubmitted: true,
      loadingError: false,
      trackingDetail: {},
    });
    try {
      const trackingDetail = await shipmentService.brandedTrackShipment(
        this.state.data.trackingNumber,
        this.state.data.subdomain
      );
      if (!trackingDetail.brandDetail) {
        this.setState({
          trackingDetail,
          redirectToDefaultTrackingPage: true,
          isDefaultTrackingPage: false,
        });
      } else {
        if (this.props.initPageProperties) {
          this.props.initPageProperties(
            trackingDetail.trackingPageTemplateProperties["PageTitle1"],
            trackingDetail.trackingPageTemplateProperties["PageTitle2"],
            trackingDetail.trackingPageTemplateProperties["Theme"]
          );
        }

        this.setState(
          {
            trackingDetail,
            productList: trackingDetail.brandDetail.productList,
            isDefaultTrackingPage: trackingDetail.isDefaultTrackingPage,
          },
          () => {
            this.setState({ formSubmitted: false });
          }
        );
      }
    } catch (e) {
      this.setState({
        formSubmitted: false,
        loadingError: true,
      });
    }
  }

  render() {
    let { isLoading } = this.state;
    let comp = null;

    if (this.state.redirectToDefaultTrackingPage) {
      return (
        <DefaultTrackShipment trackingDetail={this.state.trackingDetail} />
      );
    }
    if (this.state.formSubmitted) {
      comp = (
        <>
          <br />
          {showLoadingRecordsMessage("Tracking your shipment... Please wait.")}
        </>
      );
    } else if (
      !this.state.trackingDetail.trackingList ||
      this.state.trackingDetail.trackingList.length === 0
    ) {
      comp = (
        <Container>
          <br />
          <br />
          <br />
          <br />
          <Alert color="default" className="text-center">
            Tracking information not available.
          </Alert>
        </Container>
      );
    } else {
      if (
        this.props.selectedThemeProperties &&
        this.props.selectedThemeProperties.length > 0
      ) {
        this.state.trackingDetail.trackingPageTemplateProperties =
          this.props.selectedThemeProperties;
      }

      comp = this.trackingDetails();
    }

    return (
      <>
        {this.detailImageModal()}
        {(this.state.isDefaultTrackingPage || this.state.loadingError) && (
          <DemoNavbar type="white" isIndexPage={false} />
        )}
        {!this.state.formSubmitted &&
          !this.props.testPage &&
          !this.state.isDefaultTrackingPage &&
          !this.state.loadingError && (
            <BrandedNavbar
              type="branded"
              titleMessage={
                this.state.trackingDetail.brandDetail
                  ? this.state.trackingDetail.brandDetail.shortName
                  : ""
              }
              logoURL={
                this.state.trackingDetail.brandDetail &&
                !this.props.testPage &&
                this.state.trackingDetail.brandDetail.logoURL
              }
            />
          )}
        <div
          className={`${
            this.props.selectedThemeProperties
              ? this.props.selectedThemeProperties["PageBackgroundColour"]
              : this.state.trackingDetail.trackingPageTemplateProperties
              ? this.state.trackingDetail.trackingPageTemplateProperties[
                  "PageBackgroundColour"
                ]
              : ""
          }`}
        >
          {comp}
          {this.footer()}
        </div>
        {/* <InstagramEmbed
          url="https://instagr.am/p/Zw9o4/"
          clientAccessToken="348634090553534|50723cd4474ff7024904cc81716a9b57"
          maxWidth={320}
          hideCaption={false}
          containerTagName="div"
          protocol=""
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
        /> */}
        {/* <DemoFooter /> */}
      </>
    );
  }

  setCustomTags(i) {
    return i.tags.map((t) => {
      return (
        <div key={t.value} style={this.customTagStyle}>
          {t.title}
        </div>
      );
    });
  }

  trackingDetails() {
    var productList = [];
    if (this.state.trackingDetail.brandDetail) {
      this.state.productList.map((pr) => {
        // this.state.trackingDetail.brandDetail.productList.map((pr) => {
        const tags = [
          // pr.oldValue && {
          //   value: (
          //     <del className="font-weight-bolder">
          //       <span
          //         dangerouslySetInnerHTML={{ __html: pr.currencyHtmlCode }}
          //       ></span>{" "}
          //       {pr.oldValue}
          //     </del>
          //   ),
          //   title: "Old Price",
          // },
          pr.currentValue && {
            value: "Hallo",
            title: "New Price",
          },
          pr.name && {
            value: "done",
            title: "Product Name",
          },
        ];

        productList[productList.length] = {
          src: pr.imageURL,
          width: 1080,
          height: 1080,
          // tags: tags.filter((t) => t != null),
          // caption: pr.description,
        };
      });
      // productList = productList.map((i) => {
      //   i.customOverlay = (
      //     <div style={this.captionStyle}>
      //       <div>{i.caption}</div>
      //       {i.hasOwnProperty("tags") && this.setCustomTags(i)}
      //     </div>
      //   );
      //   return i;
      // });
    }

    return (
      <Container>
        {!this.props.testPage && (
          <>
            <br />
            <br />
            <br />
          </>
        )}
        <br />

        <div className="min-vh-100">
          <Row className="no-gutters">
            <Col
              className={`col-12 m-md-1 ${
                !this.isEmptyProductList() ? " col-md-6 col-lg-4" : ""
              }`}
            >
              {this.loadTrackingHeader()}
              <div className="border rounded bg-white mb-1 mt--2">
                <div
                  className={`wirepackage-vertical-scroll pl-2 pt-4 ${
                    !this.isEmptyProductList() ? "" : " bg-light"
                  }`}
                  style={{
                    height: this.state.trackingDetail.brandDetail
                      .showWirePackageContact
                      ? 420
                      : 400,
                    minHeight: this.state.trackingDetail.brandDetail
                      .showWirePackageContact
                      ? 420
                      : 400,
                    maxHeight: this.state.trackingDetail.brandDetail
                      .showWirePackageContact
                      ? 420
                      : 400,
                  }}
                >
                  <Timeline
                    items={this.state.trackingDetail.trackingList}
                    format="hh:mm a"
                  />
                </div>
                <div
                  className={`p-2 border rounded               
                  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingRouteBackgroundColour"]}
                  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingRouteTextColour"]}`}
                >
                  <div className="font-weight-bold">
                    <span className="badge badge-default mb-0">
                      <h6 className="text-white p-0 m-0">
                        {this.state.trackingDetail.serviceType}
                      </h6>
                    </span>{" "}
                    {/* {this.state.trackingDetail.trackingNumbers.length > 1 && (
                      <span>
                        <h6
                          className={`p-0 m-0 ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingRouteTextColour"]}`}
                        >
                          (Your shipment will be moved in several packages.)
                        </h6>
                      </span>
                    )} */}
                  </div>

                  {/* {this.state.trackingDetail.trackingNumbers.map(
                    (tn, index) => {
                      return (
                        <h6
                          key={`h6-${index}`}
                          className={`p-0 m-0 ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingRouteTextColour"]}`}
                        >
                          {tn}
                        </h6>
                      );
                    }
                  )} */}
                </div>
                {this.loadDeliveryEnquiryInformation()}
              </div>
            </Col>
            {!this.isEmptyProductList() && (
              <Col className=" mb-md-2 mb-1">
                <div>
                  {/* <div
                  className={`p-2 rounded border-bottom text-center
                  ${
                    this.state.trackingDetail.trackingPageTemplateProperties[
                      "ShopHeaderBackgroundColour"
                    ]
                  }
                  ${
                    this.state.trackingDetail.trackingPageTemplateProperties[
                      "ShopHeaderTextColour"
                    ]
                  }
                  ${
                    !this.props.title1 &&
                    !this.state.trackingDetail.trackingPageTemplateProperties[
                      "PageTitle1"
                    ] &&
                    !this.props.title2 &&
                    !this.state.trackingDetail.trackingPageTemplateProperties[
                      "PageTitle2"
                    ] &&
                    "d-none"
                  }`}
                >
                  <Row>
                    <Col>
                       <h4
                        className={`text-default font-weight-bold ${this.state.trackingDetail.trackingPageTemplateProperties["ShopHeaderTextColour"]}`}
                      >
                        {this.props.title1 ||
                          this.state.trackingDetail
                            .trackingPageTemplateProperties["PageTitle1"]}
                      </h4>
                      <h6
                        className={`text-default font-weight-bold ${this.state.trackingDetail.trackingPageTemplateProperties["ShopHeaderTextColour"]}`}
                      >
                        {this.props.title2 ||
                          this.state.trackingDetail
                            .trackingPageTemplateProperties["PageTitle2"]}
                      </h6>
                    </Col>
                  </Row>
                </div> */}
                  <div
                    className="font-weight-bold px-4 pt-1 mb-2 w-100 text-center d-none d-md-block"
                    style={{
                      display: "block",
                      overflowX: "hidden",
                      overflowY: "auto",
                      height: 650,
                    }}
                  >
                    {this.loadImage()}
                    {/* <Gallery photos={productList} /> */}
                  </div>

                  <div
                    className="font-weight-bold px-4 pt-1 mb-2 w-100 text-center d-md-none"
                    style={{
                      display: "block",
                    }}
                  >
                    {this.loadImage()}
                    {/* <Gallery photos={productList} /> */}
                  </div>

                  {!this.state.isDefaultTrackingPage && this.shopFooter()}
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Container>
    );
  }

  footer() {
    if (this.state.isDefaultTrackingPage) {
      return (
        this.state.trackingDetail.trackingPageTemplateProperties && (
          <DemoFooter
            bgColor={
              this.state.trackingDetail.trackingPageTemplateProperties
                .ShopFooterBackgroundColour
            }
          />
        )
      );
    }

    return (
      <div className="text-center pb-3 text-dark">
        <div className="text-dark text-sm text-center">
          <a
            href="https://cdn.wirepackage.com/files/terms.html"
            target="_blank"
            className="text-primary"
          >
            Terms
          </a>{" "}
          and{" "}
          <a
            href="https://cdn.wirepackage.com/files/privacy-policy.html"
            target="_blank"
            className="text-primary"
          >
            Privacy Policy
          </a>
        </div>
        <div className="copyright text-muted text-center">
          <a
            className="font-weight-normal ml-1"
            href="http://localhost:3000/"
            target="_blank"
          >
            WirePackage Technologies Limited
          </a>{" "}
          © {new Date().getFullYear()}
        </div>
      </div>
    );
  }

  isEmptyProductList() {
    return !(this.state.productList && this.state.productList.length > 0);
  }

  loadTrackingHeader() {
    if (!this.isEmptyProductList()) {
      return this.availableProductListHeader();
    } else {
      return this.emptyProductListHeader();
    }
  }

  availableProductListHeader() {
    return (
      <>
        {this.trackingHeader()}
        <div
          className={`font-weight-bold p-2 rounded border-bottom border-top text-center border-right border-left border-light               
  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingRouteBackgroundColour"]}
  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingRouteTextColour"]}`}
        >
          <span>
            <h6
              className={`p-0 m-0 font-weight-bold ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingRouteTextColour"]}`}
            >
              [{this.state.trackingDetail.origin.country}]{" "}
              <span className="fas fa-arrow-right" />{" "}
              {this.state.trackingDetail.destination.countryName}
            </h6>
          </span>
        </div>
        {/* <h6 className="p-0 m-0">
          <div className="font-weight-bold text-center text-underline bg-secondary mb-2 pb-2 pt-1">
            Tracking History
          </div>
        </h6> */}
      </>
    );
  }

  emptyProductListHeader() {
    return (
      <div className="bg-white p-2 rounded">
        <Row>
          <Col>
            {this.deliveryText()}
            <h5 className="text-primary">{this.deliveryDay()}</h5>
          </Col>
          <Col className="text-right">
            Route
            <h5 className="text-primary">
              {this.state.trackingDetail.origin.country}{" "}
              <span className="fas fa-arrow-right" />{" "}
              {this.state.trackingDetail.destination.countryName}
            </h5>
          </Col>
        </Row>
        {this.state.trackingDetail.accessPointAddress && (
          <Row>
            <Col>
              <Card className="bg-primary text-white m-0 p-0">
                <CardBody className="border border-lighter rounded text-center p-2 m-0">
                  <div>
                    <h6 className="p-0 m-0 text-underline text-white">
                      Pick up your package at this address (
                      <em>an ID is required to pickup</em>
                      ):
                    </h6>
                    <span className="text-md font-weight-bold">
                      {this.state.trackingDetail.accessPointAddress.address}
                      {this.state.trackingDetail.accessPointAddress.city
                        ? ", " +
                          this.state.trackingDetail.accessPointAddress.city
                        : ""}
                      {this.state.trackingDetail.accessPointAddress.country
                        ? ", " +
                          this.state.trackingDetail.accessPointAddress.country
                        : ""}
                      {this.state.trackingDetail.accessPointAddress.postalCode
                        ? ". " +
                          this.state.trackingDetail.accessPointAddress
                            .postalCode
                        : ""}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  loadDeliveryEnquiryInformation() {
    if (this.state.isDefaultTrackingPage) {
      return;
    }

    if (
      !this.state.trackingDetail.brandDetail.showWirePackageContact &&
      !this.isEmptyProductList()
    ) {
      return this.shopFooter();
    }

    if (!this.state.trackingDetail.brandDetail.showWirePackageContact) {
      return;
    }

    let enquiryInfo = (
      <div
        className={`rounded border-bottom px-4 pb-2  
  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingHeaderBackgroundColour"]}
  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingHeaderTextColour"]}`}
      >
        <div
          className={`h6 font-weight-bold text-center text-underline py-2 
    ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingHeaderBackgroundColour"]}
    ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingHeaderTextColour"]}`}
        >
          For Delivery Enquiry
          <br /> <br />
        </div>
        <Row className="mb-2 no-gutter">
          <Col className="col-9">
            <h6 className="text-white p-0 m-0">+234-818-988-5511</h6>
          </Col>
          <Col className="text-right">
            <h6 className="text-white p-0 m-0">
              <i className="fab fa-whatsapp"></i>
            </h6>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="col-9">
            <h6 className="text-white p-0 m-0">+234-818-988-5511</h6>
          </Col>
          <Col className="text-right">
            <h6 className="text-white p-0 m-0">
              <i className="fas fa-phone"></i>
            </h6>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="col-9">
            <h6 className="text-white p-0 m-0">hello@wirepackage.com</h6>
          </Col>
          <Col className="text-right">
            <h6 className="text-white p-0 m-0">
              <i className="fas fa-envelope"></i>
            </h6>
          </Col>
        </Row>
      </div>
    );

    if (this.isEmptyProductList()) {
      enquiryInfo = (
        <Row className="no-gutters">
          <Col className="col-12 col-lg-4 mr-md-1">{enquiryInfo}</Col>
          <Col> {this.shopFooter()} </Col>
        </Row>
      );
    }

    return enquiryInfo;
  }

  loadImage() {
    const productDisplays = [];
    let product = null;
    let product2 = null;

    for (
      let index = 0;
      index < this.state.productList.length;
      index = index + 2
    ) {
      product = this.state.productList[index];

      if (index + 1 == this.state.productList.length) {
        productDisplays[productDisplays.length] = (
          <Row key={productDisplays.length}>
            <Col className="col-12 col-lg-6 px-0 mb-1">
              <div className="mr-lg-1 rounded-lg border border-light bg-white">
                <Row>
                  <Col className="col-12 pr-1">
                    <div className="p-1 pr-3">
                      <img
                        src={
                          product.imageURL
                            ? product.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-fluid "
                        alt={product.name}
                        style={{
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail: this.state.productList[index],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                    </div>
                  </Col>
                  {/* <Col className="col-6 col-sm-6 col-md-6 col-lg-6  pl-md-0   pl-lg-0">
                    <h5 className="font-weight-bolder text-dark">
                      {product.name}
                    </h5>
                    <div> {product.description}</div>
                    <h6 className="font-weight-bold">
                      {product.currency}{" "}
                      {utils.numberFormatter(product.currentValue, 2)}
                    </h6>
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
        );
      } else {
        product2 = this.state.productList[index + 1];

        productDisplays[productDisplays.length] = (
          <Row key={productDisplays.length}>
            <Col className="col-12 col-lg-6 px-0 mb-1">
              <div className="mr-lg-1 rounded-lg border border-light bg-white">
                <Row>
                  <Col className="col-12 pr-1">
                    <div className="p-1 pr-3">
                      <img
                        src={
                          product.imageURL
                            ? product.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-fluid "
                        alt="Product Image"
                        style={{
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail: this.state.productList[index],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                    </div>
                  </Col>
                  {/* <Col className="col-6 col-sm-6 col-md-6 col-lg-6  pl-md-0   pl-lg-0">
                    <h5 className="font-weight-bolder text-dark">
                      {product.name}
                    </h5>
                    <div> {product.description}</div>
                    <h6 className="font-weight-bold">
                      {product.currency}{" "}
                      {utils.numberFormatter(product.currentValue, 2)}
                    </h6>
                  </Col> */}
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 px-0 mb-1">
              <div className="mr-lg-1 rounded-lg border border-light bg-white">
                <Row>
                  <Col className="col-12 pr-1">
                    <div className="p-1 pr-3">
                      <img
                        src={
                          product2.imageURL
                            ? product2.imageURL
                            : this.state.defaultImageURL
                        }
                        className="rounded float-center img-fluid "
                        alt="Product Image"
                        style={{
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.setState({
                            modalProductDetail:
                              this.state.productList[index + 1],
                          });
                          this.toggleModal("detailImageModal");
                        }}
                      />
                    </div>
                  </Col>
                  {/* <Col className="col-6 col-sm-6 col-md-6 col-lg-6  pl-md-0   pl-lg-0">
                    <h5 className="font-weight-bolder text-dark">
                      {product2.name}
                    </h5>
                    <div> {product.description}</div>
                    <h6 className="font-weight-bold">
                      {product2.currency}{" "}
                      {utils.numberFormatter(product2.currentValue, 2)}
                    </h6>
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
        );
      }
    }
    return productDisplays;
  }

  loadImageOld() {
    const productList = this.state.productList;
    return productList.map((product, index) => {
      return (
        <div className=" border">
          <Row>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div style={{ height: 150, width: 150 }}>
                <img
                  src={
                    product.imageURL
                      ? product.imageURL
                      : this.state.defaultImageURL
                  }
                  className="rounded float-center img-fluid"
                  alt="Product Image"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    this.setState({
                      modalProductDetail: this.state.productList[index],
                    });
                    this.toggleModal("detailImageModal");
                  }}
                />
              </div>
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
              <span className="font-weight-bolder text-dark text-lg text-left">
                {product.name}
              </span>
              <div> {product.description}</div>
              <div>
                {product.currency}{" "}
                {utils.numberFormatter(product.currentValue, 2)}
              </div>
              <br />
            </Col>
          </Row>
        </div>

        // <div key={`div-img-${index}`} className="mb-2">
        //   <div
        //     className="mr-2 mb-2"
        //     key={`img1-${product.id}`}
        //     style={{ maxHeight: 150, maxWidth: 150 }}
        //   >
        //     <img
        //       src={
        //         product.imageURL ? product.imageURL : this.state.defaultImageURL
        //       }
        //       className="rounded float-center img-fluid"
        //       alt="Product Image"
        //       style={{
        //         // maxHeight: "100%",
        //         // maxWidth: "100%",
        //         objectFit: "contain",
        //         cursor: "pointer",
        //       }}
        //       // onClick={(e) => {
        //       //   this.setState({
        //       //     modalProductDetail: this.state.productList[index],
        //       //   });
        //       //   this.toggleModal("detailImageModal");
        //       // }}
        //     />
        //   </div>
        //   <div>{product.price}</div>
        // </div>
      );
    });
  }

  trackingHeader() {
    if (
      this.state.trackingDetail.delivered ||
      this.state.trackingDetail.deliveryDay
    ) {
      return (
        <div
          className={`p-2 rounded border-bottom text-center 
                  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingHeaderBackgroundColour"]}
                  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingHeaderTextColour"]}`}
        >
          <Row>
            <Col>
              <span>{this.deliveryText()}</span>
              <h2
                className={`font-weight-bold                
                  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingHeaderTextColour"]}`}
              >
                {this.deliveryDay()}
              </h2>
              <h5
                className={`font-weight-bold                
                  ${this.state.trackingDetail.trackingPageTemplateProperties["TrackingHeaderTextColour"]}`}
              >
                {/* {this.statusText()} */}
              </h5>
            </Col>
          </Row>
          {this.state.trackingDetail.accessPointAddress && (
            <Row>
              <Col>
                <Card className="bg-white text-dark m-0 p-0">
                  <CardBody className="border border-lighter rounded text-center p-2 m-0">
                    <div>
                      <h6 className="p-0 m-0 text-underline text-dark">
                        Pick up your package at this address <br />(
                        <em>ID is required for pickup</em>
                        ):
                      </h6>
                      <span className="text-md font-weight-bold">
                        {this.state.trackingDetail.accessPointAddress.address}
                        {this.state.trackingDetail.accessPointAddress.city
                          ? ", " +
                            this.state.trackingDetail.accessPointAddress.city
                          : ""}
                        {this.state.trackingDetail.accessPointAddress.country
                          ? ", " +
                            this.state.trackingDetail.accessPointAddress.country
                          : ""}
                        {this.state.trackingDetail.accessPointAddress.postalCode
                          ? ". " +
                            this.state.trackingDetail.accessPointAddress
                              .postalCode
                          : ""}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      );
    }
  }

  deliveryText() {
    if (this.state.trackingDetail.delivered) {
      return "Date Delivered";
    } else if (this.state.trackingDetail.deliveryDay) {
      return "Estimated Delivery Date";
    }
  }

  statusText() {
    if (this.state.trackingDetail.delivered) {
      return "Package Delivered";
    } else if (this.state.trackingDetail.deliveryDay) {
      return "Your order is on the way.";
    }
  }

  deliveryDay() {
    // if (
    //   this.state.trackingDetail.deliveryDay &&
    //   this.state.trackingDetail.deliveryTime
    // ) {
    //   return (
    //     this.state.trackingDetail.deliveryDay +
    //     " at " +
    //     this.state.trackingDetail.deliveryTime
    //   );
    // } else

    if (this.state.trackingDetail.deliveryDay) {
      return this.state.trackingDetail.deliveryDay;
    }

    // else if (this.state.trackingDetail.deliveryTime) {
    //   return this.state.trackingDetail.deliveryTime;
    // }
  }

  shopFooter() {
    return (
      <>
        <footer
          className={`footer pb-2 pt-3 rounded ${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterBackgroundColour}`}
        >
          <Container>
            <div className="d-none d-lg-block">
              <Row className="row-grid align-items-center">
                <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <>
                    {this.state.trackingDetail.brandDetail.showContactEmail &&
                      this.state.trackingDetail.brandDetail.contactEmail && (
                        <h6
                          className={`${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                        >
                          <i className="fas fa-envelope"></i>
                          <span className="pl-2">
                            {this.state.trackingDetail.brandDetail.contactEmail}
                          </span>
                        </h6>
                      )}
                    {this.state.trackingDetail.brandDetail
                      .showContactPhoneNumber &&
                      this.state.trackingDetail.brandDetail
                        .contactPhoneNumber && (
                        <h6
                          className={`${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                        >
                          <i className="fas fa-phone"></i>{" "}
                          <span className="pl-2">
                            {
                              this.state.trackingDetail.brandDetail
                                .contactPhoneNumber
                            }
                          </span>
                        </h6>
                      )}
                    {this.state.trackingDetail.brandDetail.showWhatsAppNumber &&
                      this.state.trackingDetail.brandDetail
                        .whatsAppPhoneNumber && (
                        <a
                          href={`https://wa.me/${this.state.trackingDetail.brandDetail.whatsAppPhoneNumber}`}
                          target="_blank"
                        >
                          <h6
                            className={`${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                          >
                            <i className="font-weight-bold fab fa-whatsapp"></i>{" "}
                            <span className="pl-2">
                              {" "}
                              {
                                this.state.trackingDetail.brandDetail
                                  .whatsAppPhoneNumber
                              }
                            </span>
                          </h6>
                        </a>
                      )}
                  </>
                  {this.state.trackingDetail.brandDetail.showSocialMediaLinks &&
                    this.state.trackingDetail.brandDetail
                      .socialMediaAccounts && (
                      <>
                        {this.state.trackingDetail.brandDetail.socialMediaAccounts.map(
                          (sma, index) => {
                            return (
                              sma.pageURL && (
                                <span
                                  key={`h6-1-${index}`}
                                  className={`h4 font-weight-bold ${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                                >
                                  <a
                                    className={
                                      this.state.trackingDetail
                                        .trackingPageTemplateProperties
                                        .ShopFooterTextColour
                                    }
                                    href={sma.pageURL}
                                    target="_blank"
                                  >
                                    <i className={`pr-2 ${sma.htmlClass}`}></i>
                                  </a>{" "}
                                  <span className="font-italic">
                                    {sma.handle}
                                  </span>
                                </span>
                              )
                            );
                          }
                        )}
                      </>
                    )}
                </Col>
                <Col className="text-right">
                  <h4
                    className={`font-weight-bolder ${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                  >
                    {this.state.trackingDetail.brandDetail.organizationName}
                  </h4>
                  {/* <h6 className="text-white">
                    WirePackage Technologies Limited
                  </h6>
                  <h6 className="text-white mb-2">RC 1793843</h6> */}
                  <h6
                    className={
                      this.state.trackingDetail.trackingPageTemplateProperties
                        .ShopFooterTextColour
                    }
                  >
                    {this.state.trackingDetail.brandDetail.address},{" "}
                    {this.state.trackingDetail.brandDetail.state},{" "}
                    {this.state.trackingDetail.brandDetail.country}.
                  </h6>
                  <br />
                  {this.state.trackingDetail.brandDetail.showWebsiteLink &&
                    this.state.trackingDetail.brandDetail.websiteURL && (
                      <h6
                        className={`mb-2 font-weight-bolder ${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                      >
                        {this.state.trackingDetail.brandDetail.websiteURL}
                      </h6>
                    )}
                </Col>
              </Row>
            </div>
            <div className="d-block d-lg-none">
              <div>
                <h4
                  className={`font-weight-bolder ${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                >
                  {this.state.trackingDetail.brandDetail.organizationName}
                </h4>
                {/* <h6 className="text-white">
                    WirePackage Technologies Limited
                  </h6>
                  <h6 className="text-white mb-2">RC 1793843</h6> */}
                <h6
                  className={
                    this.state.trackingDetail.trackingPageTemplateProperties
                      .ShopFooterTextColour
                  }
                >
                  {this.state.trackingDetail.brandDetail.address},{" "}
                  {this.state.trackingDetail.brandDetail.state},{" "}
                  {this.state.trackingDetail.brandDetail.country}.
                </h6>

                {this.state.trackingDetail.brandDetail.showWebsiteLink &&
                  this.state.trackingDetail.brandDetail.websiteURL && (
                    <h6
                      className={`font-weight-bolder ${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                    >
                      {this.state.trackingDetail.brandDetail.websiteURL}
                    </h6>
                  )}
              </div>
              <br />
              <div>
                <>
                  {this.state.trackingDetail.brandDetail.showContactEmail &&
                    this.state.trackingDetail.brandDetail.contactEmail && (
                      <h6
                        className={`${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                      >
                        <i className="fas fa-envelope"></i>
                        <span className="pl-2">
                          {this.state.trackingDetail.brandDetail.contactEmail}
                        </span>
                      </h6>
                    )}
                  {this.state.trackingDetail.brandDetail
                    .showContactPhoneNumber &&
                    this.state.trackingDetail.brandDetail
                      .contactPhoneNumber && (
                      <h6
                        className={`${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                      >
                        <i className="fas fa-phone"></i>{" "}
                        <span className="pl-2">
                          {
                            this.state.trackingDetail.brandDetail
                              .contactPhoneNumber
                          }
                        </span>
                      </h6>
                    )}
                  {this.state.trackingDetail.brandDetail.showWhatsAppNumber &&
                    this.state.trackingDetail.brandDetail
                      .whatsAppPhoneNumber && (
                      <a
                        href={`https://wa.me/${this.state.trackingDetail.brandDetail.whatsAppPhoneNumber}`}
                        target="_blank"
                      >
                        <h6
                          className={`${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                        >
                          <i className="font-weight-bold fab fa-whatsapp"></i>{" "}
                          <span className="pl-2">
                            {" "}
                            {
                              this.state.trackingDetail.brandDetail
                                .whatsAppPhoneNumber
                            }
                          </span>
                        </h6>
                      </a>
                    )}
                </>

                {this.state.trackingDetail.brandDetail.showSocialMediaLinks &&
                  this.state.trackingDetail.brandDetail.socialMediaAccounts && (
                    <>
                      {this.state.trackingDetail.brandDetail.socialMediaAccounts.map(
                        (sma, index) => {
                          return (
                            sma.pageURL && (
                              <span
                                key={`h6-1-${index}`}
                                className={`h4 font-weight-bold ${this.state.trackingDetail.trackingPageTemplateProperties.ShopFooterTextColour}`}
                              >
                                <a
                                  className={
                                    this.state.trackingDetail
                                      .trackingPageTemplateProperties
                                      .ShopFooterTextColour
                                  }
                                  href={sma.pageURL}
                                  target="_blank"
                                >
                                  <i className={`pr-2 ${sma.htmlClass}`}></i>
                                </a>{" "}
                                <span className="font-italic">
                                  {sma.handle}
                                </span>
                              </span>
                            )
                          );
                        }
                      )}
                    </>
                  )}
              </div>
            </div>
          </Container>
        </footer>
      </>
    );
  }

  detailImageModal = () => {
    const modalProductDetail = this.state.modalProductDetail;
    return (
      <Modal
        className="modal-dialog-centered"
        contentClassName="bg-secondary"
        isOpen={this.state.detailImageModal}
        toggle={() => {
          this.toggleModal("detailImageModal");
        }}
        backdrop="static"
        size="lg"
      >
        <div
          className="modal-body p-3 text-justify bg-white text-default d-flex justify-content-center bg-transparents"
          // style={
          //   {
          //     // height: 500,
          //     // width: 500,
          //     // objectFit: "contain",
          //   }
          // }
        >
          <img
            src={
              modalProductDetail
                ? modalProductDetail.imageURL
                : this.state.defaultImageURL
            }
            className="rounded float-center img-fluid"
            alt="Product Image"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              // objectFit: "contain",
            }}
          />
        </div>
        <div className="modal-footer reflection-bg-secondary">
          {modalProductDetail && (
            <>
              <span className="font-weight-bolder text-dark text-lg text-left">
                {modalProductDetail.name}
              </span>{" "}
              ||
              <div> {modalProductDetail.description}</div> ||
              <div>
                {modalProductDetail.currency}{" "}
                {utils.numberFormatter(modalProductDetail.currentValue, 2)}
              </div>
            </>
          )}
          <Button
            // size="sm"
            className="ml-auto"
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.toggleModal("detailImageModal");
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  };
}

export default TrackShipment_1;
