/*eslint-disable*/
import React from "react";
// reactstrap components
import { Col, Container, Row } from "reactstrap";

// Core Components

function DemoFooter(props) {
  return (
    <>
      <footer
        className={`footer ${props.bgColor ? props.bgColor : "bg-primary"}`}
        id="contact"
      >
        <Container>
          <div className="d-none d-md-block">
            <Row className="row-grid align-items-center">
              <Col className="col-xs-12 col-sm-12 col-md-6">
                <h6 className="text-white font-weight-bold">
                  <i className="fas fa-envelope"></i>
                  <span className="pl-2">hello@wirepackage.com</span>
                </h6>
                <h6 className="text-white font-weight-bold">
                  <i className="fas fa-phone"></i>{" "}
                  <span className="pl-2">+234-818-988-5511</span>
                </h6>
                <a href="https://wa.me/2348189885511" target="_blank">
                  <h6 className="text-white font-weight-bold">
                    <i className="fab fa-whatsapp"></i>{" "}
                    <span className="pl-2">+234-818-988-5511</span>
                  </h6>
                </a>
                <h6 className="text-white font-weight-bold mb-0">
                  <a
                    className="text-white"
                    href="https://www.facebook.com/wirepackage"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square pr-2"></i>
                  </a>{" "}
                  <a
                    className="text-white"
                    href="https://www.instagram.com/wirepackage"
                    target="_blank"
                  >
                    <i className="fab fa-instagram pr-2"></i>
                  </a>{" "}
                  <i className="fab fa-twitter pr-2"></i>{" "}
                  <span className="font-italic">&#64;wirepackage</span>
                </h6>
              </Col>
              <Col className="text-right">
                <h4 className="text-white font-weight-bolder">WirePackage</h4>
                <h6 className="text-white">WirePackage Technologies Limited</h6>
                <h6 className="text-white mb-2">RC 1793843</h6>
                <h6 className="text-white">
                  Number 9, Bode Thomas Street, Surulere, Lagos, Nigeria.
                </h6>
              </Col>
            </Row>
          </div>
          <div className="d-block d-md-none">
            <Row className="row-grid align-items-center">
              <Col className="col-12">
                <h6 className="text-white font-weight-bold">
                  <i className="fas fa-envelope"></i>
                  <span className="pl-2">hello@wirepackage.com</span>
                </h6>
                <h6 className="text-white font-weight-bold">
                  <i className="fas fa-phone"></i>{" "}
                  <span className="pl-2">+234-818-988-5511</span>
                </h6>
                <a href="https://wa.me/2348189885511" target="_blank">
                  <h6 className="text-white font-weight-bold">
                    <i className="fab fa-whatsapp"></i>{" "}
                    <span className="pl-2">+234-818-988-5511</span>
                  </h6>
                </a>
                <h6 className="text-white font-weight-bold mb-0">
                  <a
                    className="text-white"
                    href="https://www.facebook.com/wirepackage"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square pr-2"></i>
                  </a>{" "}
                  <a
                    className="text-white"
                    href="https://www.instagram.com/wirepackage"
                    target="_blank"
                  >
                    <i className="fab fa-instagram pr-2"></i>
                  </a>{" "}
                  <i className="fab fa-twitter pr-2"></i>{" "}
                  <span className="font-italic">&#64;wirepackage</span>
                </h6>
              </Col>
              <Col className="col-12">
                <h4 className="text-white font-weight-bolder">WirePackage</h4>
                <h6 className="text-light">WirePackage Technologies Limited</h6>
                <h6 className="text-light mb-2">RC 1793843</h6>
                <h6 className="text-light">
                  Number 9, Bode Thomas Street, Surulere, Lagos, Nigeria.
                </h6>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DemoFooter;
